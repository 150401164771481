var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isReady)?_c('VueSlickCarousel',_vm._b({ref:"carouselRef",staticClass:"Carousel",class:{ '--withButtons': _vm.hasButtons, '--withPagination': _vm.hasPagination },on:{"swipe":_vm.onSwipe,"init":_vm.onInit}},'VueSlickCarousel',Object.assign({}, {pauseOnHover: _vm.pauseOnHover,
                            vertical: _vm.vertical,
                            centerMode: _vm.centralize,
                            infinite: _vm.inLoop,
                            autoplaySpeed: _vm.delay,
                            slidesPerRow: _vm.perPage,
                            dots: _vm.hasPagination,
                            draggable: _vm.draggable,
                            variableWidth: _vm.variableWidth},
                            _vm.carouselProps),false),[_vm._t("default",null,null,{ handleOnItemClick: _vm.handleOnItemClick, onClick: _vm.onClick })],2):_c('div')}
var staticRenderFns = []

export { render, staticRenderFns }